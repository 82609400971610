import React from "react";

function Gre() {
  return (
    <div>
      <div>
        <div className="acth"></div>
      </div>
    </div>
  );
}

export default Gre;
